@import "../../../mixins";

.advantages-section {
  &__swiper {
    overflow: visible;

    & .swiper {
      &-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        column-gap: var(--grid-column-gap);
        row-gap: var(--grid-row-gap);

        @include mediaTablet {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          gap: 0;
        }
      }

      &-slide {
        @include mediaTablet {
          max-width: rem(334);
        }

        @include mediaMobile {
          max-width: rem(290);
        }

        & > div {
          max-width: unset;
        }
      }
    }
  }
}
